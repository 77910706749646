<template>
    <div class="product-brand-tab-container">
        <div class="brand-tab-main-header">
            <FoodServiceTabSelect :tab="tab" :setTab="setTab"/>

            <div v-if="actionSearch" class="brands-modal__actions-wrapper">
                <StandardInput class="brands-modal__actions-search" placeholder="Buscar marcas" title="Marcas" :action="(value) => { filters.name = value }"
                    :type="'text'" />
                <Button class="brand-button" :iconName="'add_circle'" title="Adicionar Marca" :disabled="!actionSearch"
                    :text="'Adicionar Marca'" :action="() => { actionSearch = !actionSearch }" />
            </div>
        </div>


        <div v-if="actionSearch" class="brands-modal__actions-wrapper" style="width: 100%;">
            <div class="brands-modal__generic-request">Requisições Genéricas: {{ generic }}</div>
            <div class="brands-modal__objective-request">Requisições Objetivas: {{ objective }}</div>
            <div class="brands-modal__total-request">Total de Requisições: {{ total }}</div>
        </div>
        <div v-if="actionSearch" class="product-brand-page-table-container">
            <div >
                <div class="page-table-header">
                    <div class="page-table-header-text id-width">ID</div>
                    <div class="page-table-header-text brand-width">Marca</div>
                    <div class="page-table-header-text request-width" style="padding-left: 0;">Requisições</div>
                    <div class="page-table-header-text action-width">Ação</div>
                </div>
                <div v-for="(product, idx) in brands" :key="idx" :class="getSpecialBackground(idx)">
                    <div class="page-table-row">
                        <div class="page-table-desc-column id-width">
                            <div class="page-table-mobile-header">ID</div>
                            {{ product.id || '-' }}
                        </div>
                        <div class="page-table-desc-column brand-width">
                            <div class="page-table-mobile-header">Marca</div>
                            {{ product.brand }}
                        </div>
                        <div class="request-width">
                            <div class="page-table-mobile-header">Requisições</div>
                            {{ product.resq ? product.resq : '--' }}
                        </div>
                        <div class="page-table-desc-column action-width">
                            <div class="page-table-mobile-header">Ação</div>
                            <div>
                                <span @click="showEditBrandsModal(product)" class="material-icons-outlined edit-icon">
                                    edit
                                </span>
                                <span class="material-icons-outlined delete-icon" title="Remover"
                                    @click="delete_product(product,idx)">
                                    delete
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="new-brand-container" v-if="!actionSearch">
            <div class="new-brand__back-button">
                <Button :iconName="'arrow_back'" title="Adicionar Marca" :text="'Voltar'"
                    :action="() => { actionSearch = true }" />
            </div>
            <div class="new-brand__title-wrapper">
                <span class="new-brand__title">Nova Marca</span>
            </div>
            <SearchV2 class="new-brand__input" :placeholder="'Digite a nova marca'" :icon="'add'" v-model="new_brand"
                @search="save()" />
        </div>
        <cc-loader-full v-if="isLoading" />
        <EditProductsBrandsModal v-if="isEditBrandsModalOpen" @close="isEditBrandsModalOpen = false"
            :brandId="brandProductId.id" @reload="reload" />
    </div>
</template>

<script>
import Button from '@/components/ui/buttons/StandardButton.vue'
import SearchV2 from '@/components/ui/inputs/search.componentV2.vue'
import StandardSearch from '@/components/ui/inputs/StandardSearch.vue'
import * as CONSTANTS from '@/constants/constants';
import ProductService from '@/services/v3/management/product.service';
import ErrorHandlerService from "@/services/ErrorHandlerService";
import EditProductsBrandsModal from './edit-product-brands.modal';
import StandardInput from '@/components/ui/inputs/StandardInput.vue';
import FoodServiceTabSelect from './FoodServiceTabSelect.vue';
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
export default {
    name: "ProductBrandTab",
    mixins: [ loaderMixin ],
    components: {
        StandardSearch,
        Button,
        SearchV2,
        EditProductsBrandsModal,
        StandardInput,
        FoodServiceTabSelect
    },
    props: [
        'product',
        'product_pattern',
        'tab',
        'setTab'
        // closeDialog: {
        //     type: Function,
        // },
        ],
    data() {
        return {
            svc: new ProductService(),
            modal: {
                title: `Marcas #${this.product.name}`,
                icon_title: 'fa fa-list',
                cancel_text: 'Voltar',
                subtitle: "Acompanhe as marcas disponíveis para seus produtos",
                style: {
                    width: "65%"
                }
            },
            filters: {
                name: ""
            },
            actionSearch: true,
            new_brand: null,
            sucess_brand: false,
            erro_brand: false,
            total: undefined,
            objective: undefined,
            generic: undefined,
            isLoading: true,
            brands: [],
            isEditBrandsModalOpen: false,
            brandProductId: {
                id: undefined,
            },
        }
    },
    methods: {
        reload() {
            this.filtred_brands()
        },
        showEditBrandsModal(brand) {
            this.brandProductId.id = brand.id
            this.isEditBrandsModalOpen = true;
        },
        getProductImage(product) {
            return `background-image: url(${product.pro_imagem}?lastmod=${Date.now()})`
        },
        getSpecialBackground(idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        close() {
            if (this.actionSearch) {
                this.$emit('close')
            } else {
                this.actionSearch = !this.actionSearch;
            }
        },
        toogleAtivoMarcas(index) {
            this.brands[index].pro_status = +!this.brands[index].pro_status

            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                params: {
                    produto_id: this.brands[index].pro_id,
                    status: this.brands[index].pro_status
                }
            }
            let url = `${CONSTANTS.API_URL}/inativarativarprodutocliente`
            axios.post(url, {}, config)
                .then(() => { })
        },
        save() {
            this.svc.add_brand(this.product.id, this.new_brand, this.product_pattern, 1)
                .then(response => response.data).then((data) => {
                    this.new_brand = null;
                    this.actionSearch = true
                    this.brands.push({ id: data.pro_id, brand: data.pro_observacao })
                    this.$forceUpdate()
                    this.showMessageSucess();
                }).catch(error => {
                    this.showMessageErro();
                })
        },
        showMessageSucess() {
            const notification = {
                type: 'sucess',
                message: 'Marca Cadastrada com sucesso!'
            }
            this.$store.dispatch('notification/add', notification);
        },
        showMessageErro() {
            const notification = {
                type: 'error',
                message: 'Não foi possível salvar a marca!'
            }
            this.$store.dispatch('notification/add', notification);
        },
        delete_product(product,index) {
            let message = (this.product_pattern) ? `Marca será excluída da base padrão!` : `Marca será excluído da base!`
            this.confirm_action({
                message: "Confirma a remoção do produto?",
                subtitle: message,
                callback: () => {
                    this.svc.delete(product.id,this.product_pattern)
                    .then(() => {
                        this.product.derivatives = this.product.derivatives.filter(d => d.id != product.id);
                        this.$forceUpdate();
                        this.present_success("Excluído!", "Marca Removida com sucesso");
                        this.brands.splice(index, 1)
                        }).catch(error => {
                            ErrorHandlerService.handle(error, this.$store);
                        })
                }
            })
        },
        filtred_brands() {
            return this.product.derivatives?.filter(deriv => {
                return deriv.brand?.toLowerCase().includes(this.filters.name?.toLowerCase())
            })
        }
    },
    mounted() {
        this.brands = this.filtred_brands()
        this.svc.getBrands(this.product.id).then(resp => {
            this.total = resp.data.total
            this.objective = resp.data.totalObjetivo
            this.generic = resp.data.totalGenerico
            for (let index = 0; index < resp.data.data.length; index++) {
                const respBrand = resp.data.data[index];
                for (let index2 = 0; index2 < this.brands.length; index2++) {
                    const brand = this.brands[index2];
                    if (respBrand.pro_observacao == brand.brand) { brand.resq = respBrand.qtd }
                }
            }
            this.isLoading = false
        })
    }
}

</script>

<style lang="scss" scoped>
.brands-modal__product-title-wrapper {
    padding: 2em;
}

.brands-modal__product-title {
    font-weight: 400;
    color: #606060;
    line-height: 22px;
    font-size: 1.8em;
}

.product-brand-tab-container {
}

.brands-modal__actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 47%;
}

.product-brand-page-table-container {
    margin: 2em;
}

.brand-tab-main-header{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 13vh;
    margin-bottom: 3vh;
}

.page-table-header {
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    color: #605F5F;
    display: flex;
    padding: 1em 0em;
}
.page-table-mobile-header{display: none;}

.page-table-header-text {
    padding-left: 10px;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
}

.page-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1.2em;
    color: #605F5F;
    align-items: center;
}

.page-table-desc-column {
    padding: 0.5em 0.7em;
    text-align: left;
}

.page-table-line-special {
    background: #F7F7F7;
}

.brand-width {
    flex: 1;
}

.request-width {
    width: 20%;
}

.action-width {
    width: 10%;
}

.id-width {
    width: 10%;
}

.delete-icon {
    color: #CB595B;
    cursor: pointer;
    font-size: 1.5em;
}

.brand-button {
    margin-top: 2.4em;
}

.new-brand-container {
    padding: 2em;
}

.new-brand__title-wrapper {
    margin-bottom: 1em;
}

.new-brand__title {
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 24px;
    font-size: 1.7em;
    color: #707070;
}

.brands-modal__actions-search {
    width: 60%;
}

.new-brand__back-button {
    display: flex;
    justify-content: end;
}

.brands-modal__generic-request {
    width: 30%;
    color: #E53535;
    background-color: rgba(230, 53, 53, 0.1);
    font-size: 1.2em;
    padding: 1vh 1vw;
    border-radius: 5px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.brands-modal__objective-request {
    width: 30%;
    color: #17A2B8;
    background-color: rgba(23, 162, 184, 0.1);
    font-size: 1.2em;
    padding: 1vh 1vw;
    font-weight: bold;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.brands-modal__total-request {
    width: 30%;
    color: #FF7110;
    background: rgba(255, 113, 16, 0.15);
    font-size: 1.2em;
    padding: 1vh 1vw;
    font-weight: bold;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.brands-modal__product-image-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 1em;
}

.upload-img-icon {
    width: 7em;
    height: 7em;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}

.edit-icon {
    margin-left: 10px;
    cursor: pointer;
    color: #505050;
    font-size: 1.5em;
}

/deep/ .products-page__input {
    padding: 25px 10px !important;
    color: #707070;
    font-size: 1.2em;
    width: 90%;
}



@media only screen and (max-width: 850px) {
    .brand-tab-main-header{
        flex-direction: column;
        height: unset;
    }
    .brands-modal__actions-wrapper{
        width: 100%;
    }
    .product-brand-page-table-container {font-size: 1.5em;}
    .page-table-header{display: none;}
    .page-table-row{
        flex-direction: column;
        gap: 1vh;
    }
    .page-table-desc-column{
        width: 100%;
        flex: unset;
        display: flex;
        justify-content: space-between;
    }
    .page-table-mobile-header{
        display: flex;
        font-weight: 700;
        font-size: 1.1em;
    }
    .request-width{
        width: 100%;
        flex: unset;
        display: flex;
        justify-content: space-between;
        padding: 0.5em 0.7em;
    }
    .upload-img-icon{margin-right: 4vw;}
}

@media only screen and (max-width: 450px) {

    .brands-modal__actions-wrapper {
        flex-direction: column;
        gap: 1vh;
    }

    .brands-modal__actions-search {
        width: 100%;
    }

    .brand-button {
        width: 100%;
    }

    .brands-modal__generic-request {
        width: 100%;
        text-align: center;
        font-size: 1.5em;
    }

    .brands-modal__objective-request {
        width: 100%;
        text-align: center;
        font-size: 1.5em;
    }

    .brands-modal__total-request {
        width: 100%;
        text-align: center;
        font-size: 1.5em;
    }
}
</style>
