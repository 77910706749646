<template>
    <div class="colum-detais-background">
        <div class="colum-detais-modal">
            <div class="colum-details-header">
                {{ name }}
                <span v-on:click="close" class="material-icons colum-detais-close">cancel</span>
            </div>
            <div class="colum-detais-body">
                <div class="colum-details-table-container">
                    <div class="colum-detais-table-header">
                        <div class="colum-details-name-width">Produto</div>
                        <div class="colum-details-name-cod">Código</div>
                        <div class="colum-details-name-brand">Marca</div>
                        <div class="colum-details-name-obs">Observação</div>
                        <div class="colum-details-name-cot">Cotação</div>
                        <div class="colum-details-name-value">Valor</div>
                    </div>
                    <div class="colum-details-table-line" v-for="(value, idx) in valueList" :key="idx" :class="{'page-table-line-special': idx % 2 != 0 }">
                        <div class="colum-details-name-width" :title="value.produto_desc">{{ value.produto_desc }}</div>
                        <div class="colum-details-name-cod">{{ value.produto_ean }}</div>
                        <div class="colum-details-name-brand" :title="value.produto_obs">{{ value.produto_obs ? value.produto_obs : '--' }}</div>
                        <div class="colum-details-name-obs" :title="value.observacao">{{ value.observacao ? value.observacao : '--' }}</div>
                        <div class="colum-details-name-cot">{{ value.cot_id }}</div>
                        <div class="colum-details-name-value">{{ value.valor.toLocaleString('pt-br', {
                                            style: 'currency', currency: 'BRL'
                                        }) }}</div>
                    </div>
                    <Pagination classes="orange justify-end"
                          :total="total_items"
                          :items_by_page_value="30"
                          :page="1"
                          :page_limit="9"
                          v-on:change="updatePage">
                    </Pagination>
                </div>
            </div>
        </div>

        <cc-loader-full v-if="isLoading" />
    </div>
</template>

<script>
import ProductService from '@/services/v3/management/product.service';
import Pagination from "@/components/cliente/base-components/Pagination";

export default {
    name: 'ColumDetails',
    props:['ColumDetail', 'close', 'id', 'name'],
    components:{
        Pagination,
    },
    data() {
        return {
            valueList:[],
            svc: new ProductService(),
            isLoading: true,
            page: 1,
            total_items: 0,
            init: undefined,
            end: undefined
        }
    },
    created() {
        this.init = this.getDateString(this.ColumDetail.mes)
        this.end = this.getDateString(this.ColumDetail.mes, 1)
        this.load()
    },
    methods:{
        getDateString(value, plus) {
            let year = parseInt(value.split(' - ')[1])
            let month = value.split(' - ')[0]
            if (month == 'Janeiro') month = 1
            else if (month == 'Fevereiro') month = 2
            else if (month == 'Março') month = 3
            else if (month == 'Abril') month = 4
            else if (month == 'Maio') month = 5
            else if (month == 'Junho') month = 6
            else if (month == 'Julho') month = 7
            else if (month == 'Agosto') month = 8
            else if (month == 'Setembro') month = 9
            else if (month == 'Outubro') month = 10
            else if (month == 'Novembro') month = 11
            else if (month == 'Dezembro') month = 12
            if(plus) {
                month += plus
                if (month > 12){
                    month = 1
                    year++
                }
            }
            return year +'-'+ month + '-1'
        },
        load() {
            this.svc.getProduct(this.id, this.init, this.end, this.page).then(resp => {
                this.valueList = resp.data.data
                setTimeout(() => {
                    this.total_items = resp.data.total
                }, 500);
                this.isLoading = false
            })
        },
        updatePage(event) {
            this.isLoading = true
            this.page = event
            this.load()
        }
    }
}
</script>

<style scoped>
.colum-detais-background{
    position: fixed;
    z-index: 10;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 125vh;
    font-size: 1vw;
}
.colum-detais-modal{
    background-color: white;
    border-radius: 10px;
    height: 100vh;
    width: 90vw;
}
.colum-details-header{
    background-color: #FF9954;
    padding: 0 3vw;
    display: flex;
    border-radius: 10px 10px 0 0;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-size: 2.5em;
    height: 10%;
}
.colum-detais-close{
    cursor: pointer;
    font-size: 1.3em;
}
.colum-detais-body{
    height: 88%;
    overflow: auto;
}
.colum-details-table-container{
    margin: 2vh 3vw;
}
.colum-detais-table-header{
    border: 0.5px solid #E5E5E5;
    display: flex;
    padding: 1vh 0;
    font-size: 1.2em;
    font-weight: 500;
    color: #605F5F;
}
.colum-details-name-width{
    margin-left: 1vw;
    flex: 1;
}
.colum-details-name-cod{
    width: 13%;
}
.colum-details-name-brand{
    width: 12%;
}
.colum-details-name-obs{
    width: 15%;
}
.colum-details-name-cot{
    width: 10%;
}
.colum-details-name-value{
    width: 7.5%;
}
.colum-details-table-line{
    display: flex;
    margin-top: 1vh;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
    color: #605F5F;
    font-size: 1.1em;
    padding: 0.5vh 0;
}
.page-table-line-special{
    background: #F7F7F7;
}
</style>